import { Link } from "gatsby"
import If from "components/If"

import classnames from "classnames"

import "./TableFixed.styl"

export const Cell = ({
  to,
  color,
  status,
  header,
  sticky,
  centered,
  children,
  className,
  highlighted,
  ...props
}) => (
  <div
    className={classnames("table-fixed__cell", {
      "table-fixed__cell--highlighted": highlighted,
      "table-fixed__cell--sticky": sticky,
      "table-fixed__cell--centered": centered,
      "table-fixed__cell--header": header,
      [`table-fixed__cell--color--${color}`]: color,
      [`table-fixed__cell--status table-fixed__cell--status--${status?.value}`]:
        status,
      [className]: className,
    })}
    {...props}
  >
    <If condition={to}>
      <Link className="table-fixed__cell--link" to={to}>
        {children}
      </Link>
    </If>
    <If condition={!to}>{children}</If>
  </div>
)

export const Row = ({ children, highlighted, className, sticky, ...props }) => (
  <div
    className={classnames("table-fixed__row", {
      "table-fixed__row--highlighted": highlighted,
      "table-fixed__row--sticky": sticky,
      [className]: className,
    })}
    {...props}
  >
    {children}
  </div>
)

const TableFixed = ({ className, children, ...props }) => {
  return (
    <div className={classnames("table-view", className)} {...props}>
      <div className="table-wrapper">
        <div className="table-fixed">{children}</div>
      </div>
    </div>
  )
}

export default TableFixed
