import { useState, useCallback, useEffect } from "react"
import _pagination from "utils/pagination"
import services from "services"

const useCompanies = ({ business_id }) => {
  const [companies, setCompanies] = useState()
  const [pagination, setPagination] = useState({})

  const handleFetch = useCallback(({ header, paginated_result }) => {
    setCompanies({
      header,
      data: paginated_result.data,
    })
    setPagination({
      ...paginated_result.pagination,
      total_pages: _pagination.getTotalPages(paginated_result.pagination),
    })
  }, [])

  const fetchCompanies = useCallback(
    (pagination) => {
      services.bases.management
        .companies({ business_id, pagination })
        .then(handleFetch)
    },
    [business_id, handleFetch]
  )

  useEffect(() => {
    fetchCompanies()
  }, [fetchCompanies])

  return {
    companies,
    pagination,
    refresh: fetchCompanies,
  }
}

export default useCompanies
