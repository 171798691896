import Can from "components/Can"
import EmptyState from "components/EmptyState"
import If from "components/If"
import PageHeader from "components/PageHeader"
import Pagination from "components/Pagination"
import TableFixed, { Cell, Row } from "components/TableFixed"
import Card from "components/Card"

import useCompanies from "./hooks"

import "./Companies.styl"

const Companies = ({ pageContext: { business, link } }) => {
  const { companies, pagination, refresh } = useCompanies(business)

  return (
    <Can profile="master" see="it" otherwise={{ goTo: ROUTES.home.path }}>
      <div className="management-companies">
        <PageHeader title="Clientes potenciais" subtitle="Empresas" />
        <If
          condition={companies?.data?.length}
          renderIf={
            <TableFixed className="management-companies__table">
              <Row>
                {companies?.header?.map((item, index) => (
                  <Cell header sticky={!index} key={index}>
                    {item}
                  </Cell>
                ))}
              </Row>
              {companies?.data?.map((company) => (
                <Row key={company.group_id}>
                  <Cell sticky to={`${link}?group_id=${company.group_id}`}>
                    {company.group_name}
                  </Cell>
                  {company.leads_count.map((lead, index) => (
                    <Cell key={index}>{lead?.count}</Cell>
                  ))}
                </Row>
              ))}
            </TableFixed>
          }
          renderElse={
            <Card className="management-companies__card">
              <EmptyState className="management-companies__empty" />
            </Card>
          }
        />
        <Pagination
          currentPage={pagination.current_page}
          pages={pagination.total_pages}
          onChange={refresh}
        />
      </div>
    </Can>
  )
}

export default Companies
